
.accordion a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 20px 6px;
  color: #001027;
  font-weight: 400;
  border-bottom: 1px solid #e5e5e5;
}

.accordion a:hover {
  cursor: pointer;
  color: #0aabb3;
}


.accordion a:hover::after {
  cursor: pointer;
  color: #ffffff;
}


.accordion a:hover::after {
  border: 1px solid #0aabb3;
}

.accordion a.active {
  color: #0aabb3;
  border-bottom: 1px solid #0aabb3;
}

.accordion a::after {
  font-family: 'Ionicons';
  content: '\f218';
  position: absolute;
  float: right;
  right: 1rem;
  color: #ffffff;
  padding: 3px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  background: #34b665;
}

.accordion a.active::after {
  font-family: 'Ionicons';
  content: '\f209';
  color: #ffffff;
  border: 1px solid #0aabb3;
}

.accordion .content {
  display: none;
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
}

.accordion .content p {
  font-weight: 300;
}

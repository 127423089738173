#footer-rights {
  background: #001027;
  display: table;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  color: #fff;
}

footer .social-network .icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  margin: 0 4px;
}

footer .social-network .icon.telegram {
  background: url("../assets/images/telegram-gray.png") no-repeat 0 0;
}
footer .social-network .icon.telegram:hover {
  background: url("../assets/images/telegram-color.png") no-repeat 0 0;
}
footer .social-network .icon.twitter  {
  background: url("../assets/images/twitter-gray.png") no-repeat 0 0;
}
footer .social-network .icon.twitter:hover  {
  background: url("../assets/images/twitter-color.png") no-repeat 0 0;
}
footer .social-network .icon.youtube {
  background: url("../assets/images/youtube-gray.png") no-repeat 0 0;
}
footer .social-network .icon.youtube:hover {
  background: url("../assets/images/youtube-color.png") no-repeat 0 0;
}
footer .social-network .icon.dtube  {
  background: url("../assets/images/dtube-gray.png") no-repeat 0 0;
}
footer .social-network .icon.dtube:hover  {
  background: url("../assets/images/dtube-color.png") no-repeat 0 0;
}
footer .social-network .icon.steemit  {
  background: url("../assets/images/steemit-gray.png") no-repeat 0 0;
}
footer .social-network .icon.steemit:hover  {
  background: url("../assets/images/steemit-color.png") no-repeat 0 0;
}

.trade-name-coin {
  background: #ffffff;
  border: none;
}
.logo-footer p a, .logo-footer p {
  color: #a3a3a3;
  margin-top: 9px;
}

.logo-footer {
  text-align: center;
}

footer .container .col-md-3 {
  display: table;
  margin: 0 auto;
}

@media (max-width:991px) {
  footer .container .col-md-3 {
    margin-bottom: 30px;
  }
}

@media (max-width:767px) {
  .logo-footer > div a {
    display: block;
  }
}

#list-affiliates li {
  list-style: none;
}
#list-affiliates {
  padding: 0 0 0 5px;
}

#list-affiliates li a img {
  width: 13px;
}

.btn-terms {
  font-size: 15px;
  color: #cccccc;
  margin: -12px auto 9px;
  display: table;
}

.social-network {
  margin-top: 67px;
}

.custom-select-wrapper {
  display: table;
  margin: 0 auto;
}
.custom-select-wrapper select {
  display: none;
}
.custom-select {
  position: relative;
  display: inline-block;
}
.custom-select-trigger {
  position: relative;
  display: block;
  padding: 0 84px 0 22px;
  font-size: 15px;
  font-weight: 300;
  color: #fff;
  line-height: 30px;
  background: #0aabb3;
  border-radius: 4px;
  cursor: pointer;
}
.custom-select-trigger:after {
  position: absolute;
  display: block;
  content: '';
  width: 10px; height: 10px;
  top: 50%; right: 25px;
  margin-top: -3px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
  transition: all .4s ease-in-out;
  transform-origin: 50% 0;
}
.custom-select.opened .custom-select-trigger:after {
  margin-top: 3px;
  transform: rotate(-135deg) translateY(-50%);
}
.custom-options {
  height: 108px;
  overflow-y: scroll;
  position: absolute;
  display: block;
  top: 21px; left: 0; right: 0;
  min-width: 100%;
  margin: 15px 0;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0,0,0,.07);
  background: #fff;
  transition: all .4s ease-in-out;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
}
.custom-select.opened .custom-options {
  opacity: 1;
  z-index: 999;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}
.custom-options:before {
  position: absolute;
  display: block;
  content: '';
  bottom: 100%; right: 25px;
  width: 7px; height: 7px;
  margin-bottom: -4px;
  border-top: 1px solid #b5b5b5;
  border-left: 1px solid #b5b5b5;
  background: #fff;
  transform: rotate(45deg);
  transition: all .4s ease-in-out;
}
.option-hover:before {
  background: #f9f9f9;
}
.custom-option {
  position: relative;
  display: block;
  padding: 0 22px;
  border-bottom: 1px solid #dedede;
  font-size: 15px;
  font-weight: 600;
  color: #001027;
  line-height: 35px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}
.custom-option:first-of-type {
  border-radius: 4px 4px 0 0;
}
.custom-option:last-of-type {
  border-bottom: 0;
  border-radius: 0 0 4px 4px;
}
.custom-option:hover,
.custom-option.selection {
  background: #f9f9f9;
}
footer {
  background-color: #f2f2f2;
}

.fh5co-social-icons li {
  list-style: none;
  display: inline-block;
}

.fh5co-social-icons img {
  width: 17px;
  height: 17px;
}

.fh5co-social-icons li i {
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
}

.fh5co-social-icons li {
  height: 40px;
  width: 40px;
  background: #34b665;
  border-radius: 50%;
  text-align: center;
  padding: 12px;
  margin: 0 10px;
}

.fh5co-social-icons {
  margin: 0 auto;
  display: table;
  padding: 0;
}

.fh5co-footer-links li {
  list-style: none;
}

.fh5co-footer-links li a {
  color: #001027;
}

.fh5co-social-icons li img {
  vertical-align: top !important;
}

.fh5co-social-icons i  {
  color: #ffffff !important;
}

#footer-rights {
  background: #001027;
  display: table;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  color: #fff;
}

#footer-itens > div {
  display: inline-block;
}

#footer-itens {
  padding: 30px 0;
  display: table;
  width: 100%;
}


#fh5co-footer {
  border-top: 1px solid #eaeaea;
}

#footer-rights {
  background: #001027;
  display: table;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  color: #fff;
}

#fh5co-footer {
  border-top: 1px solid #eaeaea;
}

.custom-select {
  background: none !important;
  padding: 0 !important;
  border: none !important;
}

@media screen and (min-width: 991px) {
  .logoFooter {
    text-align: left!important;
    padding-left: 0!important;
  }
}
@media screen and (min-width: 991px) {
  .brandTextFooter, .developersName {
    text-align: right!important;
    }
  }
@media screen and (min-width: 991px) {
    .brandTextFooter {
      padding-right: 0!important;
    }
  }
#services {
    background: #fbfbfb;
}
.service-box {
    max-width: 400px;
    margin: 50px auto 0;
}
@media (min-width: 992px) {
    .service-box {
        margin: 20px auto 0;
    }
}
.service-box p {
    margin-bottom: 0;
}
.parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    background-image: url("../assets/images/crystalblockpay.jpg");
}
.parallax .content {
    opacity: 0.9;
    text-align: center;
    border: 10px solid #FFEBEE;
    width: 40%;
    padding: 25px 0;
}
.parallax .content h1 {
    color: #FFEBEE;
    font-size: 55px;
    padding: 0;
    margin: 0;
}
.parallax .content h2 {
    color: #FFEBEE;
    font-size: 20px;
    margin: 0;
    padding: 0;
}

